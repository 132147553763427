import React, {useState, useEffect} from 'react'
import DashboardCard from './DashboardCard';
import { FaCalendarAlt } from "react-icons/fa";
import { FaScroll } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";

export default function Dashboard({api}) {
  const [nextUpcomingEvent, updateNextUpcomingEvent] = useState(null)
  const [lastestEvent, updateLatestEvent] = useState(null)
  const [lastestPost, updateLatestPost] = useState(null)
  const [pendingApprovals, updatePendingApprovals] = useState(null)



  const createEvent = (<div className='font-semibold text-xl mb-1'> Create<br/>Upcoming<br/>Event</div>)
  const createPost = (<div className='font-semibold text-xl mb-1'> Create<br/>Next<br/>Post</div>)

  const getNextItem = (items) => {
    const now = new Date();
    const nowUTC = new Date(now.toISOString());
    const futureEvents = items.filter(event => new Date(event.dateAndTime) > nowUTC);
    futureEvents.sort((a, b) => new Date(a.dateAndTime) - new Date(b.dateAndTime));
    return futureEvents[0];
  };

  const getMostRecentItem = (items) => {
    const now = new Date();
    const nowUTC = new Date(now.toISOString());
    const recentEvents = items.filter(event => new Date(event.dateAndTime) <= nowUTC);
    recentEvents.sort((a, b) => new Date(b.dateAndTime) - new Date(a.dateAndTime));
    return recentEvents[0];
  };
  
  const createEventDiv = (event) => {
    const eventDateTime = new Date(event.dateAndTime);
    const eventDate = eventDateTime.toLocaleDateString();
    const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
    const eventDiv = (
      <>
        <div className='font-semibold text-xl mb-1'>
          <div className='border-b px-2 pb-1 text-ellipsis-custom'>{event.name}</div>
        </div>
        <div className='mx-2'>{eventDate}</div>
        <div className='mx-2'>{eventTime}</div>
      </>)
    return {id: event.id, eventDiv: eventDiv}
  }

  const createPostDiv = (post) => {
    const postDateTime = new Date(post.dateAndTime);
    const postDate = postDateTime.toLocaleDateString();
    const postTime = postDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
    const postDiv = (
      <>
        <div className='font-semibold text-xl mb-1'>
          <div className='border-b px-2 pb-1 text-ellipsis-custom'>{post.name}</div>
        </div>
        <div className='mx-2'>{postDate}</div>
        <div className='mx-2'>{postTime}</div>
      </>)
    return {id: post.id, post: postDiv}
  }

  const createPendingApprovalsDiv = (counter) => {
    const notificationGetter = (counter) => {
      if (counter > 0){
        return (
          <div className=' absolute top-0 left-0 -mt-3  ml-16 rounded-full h-10 w-10 flex items-center justify-center bg-red-600 text-white text-2xl font-semibold'>
            {counter}
          </div>
        )
      }
      else {
        return (<></>)
      }
    }
    return (
      <div className='flex justify-center'>
          <div className='relative'>
            <div className=''>
              <FaCheckSquare size={100}/>
            </div>
            {notificationGetter(counter)}
          </div>
        </div>
    )
  }




  useEffect(() => {
    api.listAllEvents().then((items)=>{
      updateNextUpcomingEvent(createEventDiv(getNextItem(items)))
      updateLatestEvent(createEventDiv(getMostRecentItem(items)))
    })
    api.listAllPosts().then((items) => {
      updateLatestPost(createPostDiv(getMostRecentItem(items)))
    }) 
    api.listAllApproval().then((items) => {
      const count = items.filter(approval => approval.approved === null || approval.approved === 0).length;
      updatePendingApprovals(createPendingApprovalsDiv(count))
    })
  }, [])

  return (nextUpcomingEvent && lastestEvent && lastestPost && pendingApprovals && (
    <div className="grid  grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 p-8">
      <DashboardCard internalData={createEvent} title={"Create Event"} icon={<FaCalendarAlt size={100}/>} link={'CreateEvent/'} color={'blue-500'} isSingle={false}/>
      <DashboardCard internalData={nextUpcomingEvent.eventDiv} title={"Next Event"} icon={<FaCalendarAlt size={100}/>} link={`Event/${nextUpcomingEvent.id}`} color={'blue-500'}/>
      <DashboardCard internalData={lastestEvent.eventDiv} title={"Latest Event"} icon={<FaCalendarAlt size={100}/>} link={`Event/${lastestEvent.id}`} color={'blue-500'}/>
      <DashboardCard internalData={createPost} title={"Create Post"} icon={<FaScroll size={100}/>} link={'CreatePost/'} color={'red-500'} isSingle={false}/>
      <DashboardCard internalData={lastestPost.post} title={"Latest Post"} icon={<FaScroll size={100}/>} link={`Feed`} color={'red-500'}/>
      <DashboardCard internalData={pendingApprovals} title={"Pending Approvals"} icon={<FaCheckSquare size={100}/>} link={'Approvals/'} color={'green-500'} isSingle={true}/>
    </div>
  ))
}
/*
TODO
Pending Approval counter/ Hard coded right now
Amount of people signed up for next event
Lastest event with stats
*/