import React from 'react'
import { NavLink } from "react-router-dom";

export default function EventRow({images, item, getDateAndTime}) {
    let image
    let imageStyle = {};
    if (images.find(img => img.name === item.photoUrl)){
        image = (<img src={images.find(img => img.name === item.photoUrl).image} alt={item.photoUrl} className="h-16 w-16" /> )
        let imageUrl = (<img src={images.find(img => img.name === item.photoUrl).image} alt={item.photoUrl} className="h-16 w-16" /> )
        imageStyle = {
            backgroundImage: `url(${images.find(img => img.name === item.photoUrl).image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '0.5rem 0rem 0rem 0.5rem'
        };
    }
    else {imageStyle = {
        backgroundColor: `rgb(66, 153, 225, 0.5)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '0.5rem 0rem 0rem 0.5rem'
    };}
  return (
    <NavLink to={`/Event/${item.id}`}>
        <div className='flex bg-gray-100 mb-2 rounded-lg shadow-2xl-custom h-56'>
            <div className='rounded-l-lg h-56 w-56 bg-blue-500'>
                <div className='' style={imageStyle}>
                    <div className='bg-cover-over-image text-white h-56 w-56 rounded-l-lg'>
                        <div className=' font-bold font-serif text-8xl-custom text-center'>
                            {getDateAndTime(item.dateAndTime).date.day}
                        </div>
                        <div className='text-center py-2'>
                            <div className='text-2xl'>
                                <span className='border-b-2'>{getDateAndTime(item.dateAndTime).date.month} {getDateAndTime(item.dateAndTime).date.year} </span>
                            </div>
                            <div className='text-xs'>
                                {getDateAndTime(item.dateAndTime).time}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-4 truncate'> {/* Added a div for text content */}
                <div className='text-xl font-bold pb-2 truncate'>
                    {item.type} - {item.name}
                </div>
                <div className='whitespace-pre-line h-full'>
                    {item.description}
                </div>
            </div>
        </div>
    </NavLink>
  )
}
