import React from 'react'
import { FaPlusCircle } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function ItemsTable({title, tableClassName, hasAction, action, items, search=null}) {
  const headerRow = (title) => {
    if (hasAction){
      return (
      <div className='flex justify-between'>
        <div>{title}</div>
        <NavLink to={action.to}>
          <div className='flex items-center gap-2'>
            {action.title}
            <FaPlusCircle/>
          </div>
        </NavLink>
      </div>)
    }
    else if (search != null) {
      return (
        <div className='flex justify-between items-center'>
          <div className=''>{title}</div>
         <div>{search}</div>
        </div>)
    }
    else return title
  }
  return (
    <div className='text-grey-800 overflow-x-auto pb-4 p-8'>
      <div className='font-semibold text-2xl mb-3 border-b-2'>
        {headerRow(title)}
      </div>
      <div className={tableClassName}>
        {items}
      </div>
    </div>
  )
}
