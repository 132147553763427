import React, { useEffect, useState } from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import BoxWithTitleLeftAlign from '../../components/Boxes/BoxWithTitleLeftAlign';
import DeleteModal from '../../components/Modals/DeleteModal';

export default function Account({api, account}) {
  const [allStaff, setAllStaff] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.listAllStaffUser().then((users)=>{ 
      setAllStaff(users)
    })
  }, [])

  //Functions not used due to not creating nor deleting within Staff Portal
  const onDelete = (id) => {
    setSelectedStaffId(id);
    setShowConfirmationModal(true);
  }

  const confirmDelete = () => {
    api.deleteStaffUser(selectedStaffId)
    setShowConfirmationModal(false);
    setSelectedStaffId(null);
    navigate(`/`)
  };

  const cancelDelete = () => {
    setSelectedStaffId(null);
    setShowConfirmationModal(false);
  };

  const allAccounts = () => {
    const content = (
      <>
        <div className='mb-2 grid grid-cols-6 w-full border-b-2 border-gray-800 border-opacity-50'>
          <div className='col-span-3 truncate'>
            Name/ Email
          </div>
          <div className='col-span-2 truncate'>
            Username
          </div>
          {/* Delete temp not available 
          <div className='col-span-1 truncate text-center'>
            Delete User
          </div> */}
        </div>
        {allStaff.map((staffPerson) => {
          return (
          <div className='mb-1 pb-1 grid grid-cols-6 border-b'>
            <div className='col-span-3 truncate'>
              <div className='font-semibold'>{`${staffPerson.firstName} ${staffPerson.lastName}`}</div>
              <div>{`${staffPerson.AWSEmail}`}</div>
            </div>
            <div className='col-span-2 truncate'>
              {`${staffPerson.AWSUsername}`}
            </div>
            {/* Delete temp not available
            <div className='col-span-1 truncate justify-center items-center flex mx-4'>
              <div className='border-2 border-gray-800 p-1 rounded-md'><FaTrashAlt onClick={() => onDelete(staffPerson.id)} className='text-gray-800'/></div>
            </div> */}
          </div>
        )})}
      </>
    )
    return (
      <BoxWithTitleLeftAlign title={'All Staff Users'} titleBackground={'gray-800'} content={content}/>
    )
  }

  return (allStaff &&(
    <div className="grid grid-cols-8 lg:grid-cols-6">
      <div className="col-span-1"></div>
      <div className="col-span-6 lg:col-span-4">
      <ItemsTable 
        title={`${account.firstName} ${account.lastName}`} 
        tableClassName={''}
        hasAction={false}
        // action={{to:'/CreateAccount', title:'Add Staff'}} // Removed due to creating accounts in AWS
        items = {allAccounts()}
        />
    </div>
      <div className="col-span-1"></div>
      {showConfirmationModal && (<DeleteModal handleDelete={confirmDelete} handleCancel={cancelDelete} title={'Are you sure you want to delete this staff member?'}/>)}
    </div>
  ))
}