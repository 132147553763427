import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom'
import { NavLink } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

export default function Post({post , id}) {
    // const [event, updateEvent] = useState(null)
    const [image, updateImage] = useState(null)
    // const [attendees, updateAttendees] = useState([])
    useEffect(() => {
        getImage(post)
    }, []);
    
    const getImage = async (event) => {
        let image
        let imageStyle = {};
        try {
            const module = await import(`../../assets/${event.photoUrl}`);
            const importedImage = [{ name: event.photoUrl, image: module.default }];
            image = (<img src={importedImage.find(img => img.name === event.photoUrl).image} alt={event.photoUrl} className="h-3/5 w-3/5 rounded-lg border shadow-image-custom my-2" /> )
            imageStyle = {
                backgroundImage: `url(${importedImage.find(img => img.name === event.photoUrl).image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '0.5rem 0rem 0rem 0.5rem'
            };
        } 
        catch (error) {
            image = (<></>)
            imageStyle = {
            backgroundColor: `rgb(66, 153, 225, 0.5)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '0.5rem 0rem 0rem 0.5rem'
            };
        }
        updateImage({image: image, imageStyle: imageStyle})
    }

    const getDateAndTime = (timestamp) => {
        const eventDateTime = new Date(timestamp);
        const eventDate = {
          day: eventDateTime.getDate(),
          month: eventDateTime.toLocaleDateString('en-US', { month: 'short' }),
          year: eventDateTime.getFullYear()
        };
        const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true/*, timeZoneName: 'short' */});
        return {date: eventDate, time: eventTime}
    }


    const addEditButton = () => {
        return (
            <NavLink to={`/Post/Edit/${id}`}>    
                    <div className='relative z-10'>
                        <div className=''>
                        </div>
                        <div className=' absolute top-0 left-0 -mt-5 mr-2 -ml-6 rounded-full h-10 w-10 flex items-center justify-center bg-gray-800 text-white text-2xl font-semibold'>
                        <FaEdit className='' />
                        </div>
                    </div>   
            </NavLink>
        )
    }
  return (post && image && (
    <div className='p-4 flex justify-center'>
        <div className="bg-white rounded-xl w-full lg:w-5/6 text-gray-800 shadow-2xl-custom">
        <div className='flex justify-center w-full'>
            <div/>
            <div className='w-full'>
                <div className='flex w-full justify-center'>
                    <div className='w-full'>
                        <div className='w-full text-3xl font-bold text-center bg-red-500 rounded-t-xl text-white'>{post.name}</div>
                        <div className='flex justify-center my-2'>{image.image}</div>  
                        <div className="text-md font-medium text-center">{getDateAndTime(post.dateAndTime).date.month} {getDateAndTime(post.dateAndTime).date.day}, {getDateAndTime(post.dateAndTime).date.year} - {getDateAndTime(post.dateAndTime).time}</div>
                        <div className='flex justify-center p-4'>{post.description}</div>
                    </div>
                </div>
            </div>
            <div>{addEditButton()}</div>
        </div>
        </div>
    </div>
  ))
}
