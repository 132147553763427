
import React, {useState, useEffect} from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'
import Post from './Post';

export default function Feed({api}) {
  const [posts, updatePosts] = useState(null)

  const getPostsOrder = (items) => {
    const posts = items
    posts.sort((a, b) => new Date(b.dateAndTime) - new Date(a.dateAndTime));
    return posts;
  };

  useEffect(() => {
    api.listAllPosts().then((posts)=>{ 
      updatePosts(getPostsOrder(posts))
    })
  }, [])

  return (posts && (
    <div className="grid grid-cols-8 lg:grid-cols-6">
      <div className="col-span-1"></div>
      <div className="col-span-6 lg:col-span-4">
        <ItemsTable 
          title={"Feed"} 
          tableClassName={''}
          hasAction={true}
          action={{to:'/CreatePost', title:'Add Post'}}
          items={posts.map((item) => (
            <div><Post post={item} id={item.id}/></div>
          ))}
        />
      </div>
      <div className="col-span-1"></div>
    </div>
  ))
}
