import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";

export default function ApprovalsTableRow({approval, fourthCol}) {

  return ((
      <tr className='p-4 border-b'>
        <td className='pl-1 pr-6'><NavLink to={`/Approvals/${approval.id}`}>{approval.eventName}</NavLink></td>
        <td className='pr-6'><NavLink to={`/Approvals/${approval.id}`}>{approval.childFirstName} {approval.childLastName}</NavLink></td>
        <td className='pr-6'><NavLink to={`/Approvals/${approval.id}`}>{approval.parentFirstName} {approval.parentLastName}</NavLink></td> 
        <td className='pr-6'><NavLink to={`/Approvals/${approval.id}`}>{fourthCol}</NavLink></td>
      </tr>
  ))
}
