import React from 'react'

export default function BoxWithTitleLeftAlign({title, titleBackground, content}) {
  return (
    <div className='bg-white  text-center p-auto rounded-lg shadow-2xl-custom'>
      <div className={`h-12 bg-${titleBackground} rounded-t-lg text-white text-2xl flex justify-left items-center pl-4`}>
        {title}
      </div>
      <div className='flex justify-left items-center'>
        <div className='text-left mx-4 mt-4 mb-2 w-full'>
          {content}
        </div>
      </div>
    </div>
  )
}
