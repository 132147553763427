import React, {useState, useEffect} from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'
import EventRow from './EventRow'


export default function Events({api}) {
    const [upcomingEvents, updateUpcomingEvents] = useState(null)
    const [pastEvents, updatePastEvents] = useState(null)
    const [images, updateImages] = useState(null)
    // Assuming you have image filenames stored in an array
    const imageFilenames = ['summerCamp.png', 'jazzEvent.png', 'constructionEvent.png', 'construction2Event.png'];

    // Dynamically import images
    const getImages = async (imageFilenames) => {
        const importedImages = await Promise.all(
            imageFilenames.map(async (filename) => {
                const module = await import(`../../assets/${filename}`);
                return { name: filename, image: module.default };
            })
        );
    
        // Now, importedImages is an array containing objects with name and image properties
        updateImages(importedImages);
    };
    

    const getUpcomingEvents = (items) => {
        const now = new Date();
        const nowUTC = new Date(now.toISOString());
        const futureEvents = items.filter(event => new Date(event.dateAndTime) > nowUTC);
        futureEvents.sort((a, b) => new Date(a.dateAndTime) - new Date(b.dateAndTime));
        return futureEvents;
      };
    
      const getPastEvents = (items) => {
        const now = new Date();
        const nowUTC = new Date(now.toISOString());
        const recentEvents = items.filter(event => new Date(event.dateAndTime) <= nowUTC);
        recentEvents.sort((a, b) => new Date(b.dateAndTime) - new Date(a.dateAndTime));
        return recentEvents;
      };

    useEffect(() => {
        getImages(imageFilenames)
        api.listAllEvents().then((events)=>{ 
            updateUpcomingEvents(getUpcomingEvents(events))
            updatePastEvents(getPastEvents(events))
  
        })
      }, [])

    const getDateAndTime = (timestamp) => {
        const eventDateTime = new Date(timestamp);
        const eventDate = {
          day: eventDateTime.getDate(),
          month: eventDateTime.toLocaleDateString('en-US', { month: 'short' }), // Using short month name
          year: eventDateTime.getFullYear()
        };
        const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
        return {date: eventDate, time: eventTime}
    }

  return (upcomingEvents && pastEvents && images && (
    <div className=''>
        <ItemsTable 
          title={"Upcoming events"} 
          tableClassName={'grid grid-cols-1 xl:grid-cols-2 gap-4'}
          hasAction={true}
          action={{to:"/CreateEvent", title:"Add Event"}}
          items={upcomingEvents.map((item) => (
            <EventRow images={images} item={item} getDateAndTime={getDateAndTime}/>
          ))}/>
        <ItemsTable 
          title={"Past events"} 
          tableClassName={'grid grid-cols-1 xl:grid-cols-2 gap-4'}
          hasAction={false}
          action={null}
          items={pastEvents.map((item) => (
            <EventRow images={images} item={item} getDateAndTime={getDateAndTime}/>
          ))}/>
    </div>
  ))
} 