import React, {useState, useEffect, useRef} from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'
import { FaSearch } from "react-icons/fa";
import ParticipantsTableRow from './ParticipantsTableRow';

export default function Participants({api}) {
  const [participants, updateParticipants] = useState(null)
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    //getImages(imageFilenames)
    api.listAllChildren().then((children)=>{ 
      const sortedChildren = children.slice().sort((a, b) => {
        // Assuming `lastName` is a string attribute of each child object
        const lastNameA = a.lastName.toUpperCase();
        const lastNameB = b.lastName.toUpperCase();
        if (lastNameA < lastNameB) {
          return -1;
        }
        if (lastNameA > lastNameB) {
          return 1;
        }
        return 0;
      });
      updateParticipants(sortedChildren);
        
    })
  }, [])

  const filterData = (data, term) => {
    return data.filter(row => {
      // You can customize this logic according to your table structure
      // For example, check if any column contains the search term
      return Object.values(row).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(term.toLowerCase())
      );
    });
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Trigger search here
      event.preventDefault();
      inputRef.current.blur(); // Remove focus from input
    }
  };

  // Filtered data based on the search term
  const filteredData = participants ? filterData(participants, searchTerm) : []

  const searchBar = (
            <div className='flex items-center my-2 border border-gray-300 rounded-md shadow-sm bg-white'>
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyPress}
                ref={inputRef}
                className=" px-4 py-1 text-base rounded-l-md focus:outline-none focus:border-indigo-500"
              />
              <FaSearch className='mr-1 text-gray-800 text-opacity-50' onClick={() => {inputRef.current.blur();}}/>
            </div>
  )

  return (participants && filteredData && (
    <div className="grid grid-cols-8 lg:grid-cols-6">
    <div className="col-span-1"></div>
    <div className="col-span-6 lg:col-span-4">
      <ItemsTable 
        title={"Participants"} 
        tableClassName={''}
        hasAction={false}
        action={{to:'/CreateForm', title:'Add Form'}}
        search={searchBar}
        items = {
          <div className='p-4 flex justify-center w-full'>
            <div className="bg-white rounded-xl text-gray-800 shadow-2xl-custom w-full">
              <div className='flex justify-center w-full'>
                  <div className='p-3 w-full'>
                    <table className='text-left table-auto mx-auto text-lg w-full'>
                      <thead className='border-gray-800 border-b-2'>
                          <tr>
                              <th className='pl-1 pr-6'>Child</th>
                              <th className='pr-6'>Child's Number</th>
                              <th className='pr-6'>Child's DOB</th>
                              <th className='pr-6'>Parent</th>
                          </tr>
                      </thead>
                      <tbody className='divided-y divided-gray-800'>
                          {filteredData.map((item) => (<ParticipantsTableRow api={api} attendee={item}/>))}
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
          </div> 
        }
      />
     </div>
      <div className="col-span-1"></div>
    </div>
  ))
}
