import React from 'react'
import logo from '../../assets/tmslogowhite.png'
import { FaCalendarAlt } from "react-icons/fa";
import { FaFileAlt } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { FaScroll } from "react-icons/fa";
import { FaCheckSquare } from "react-icons/fa";
import NavItem from './NavItem';
import { FaTachometerAlt } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import SocialItem from './SocialItem';
import { NavLink } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";


export default function Header({workingSite}) {
  const removeToken = () => {
    localStorage.removeItem('auth')
  }

  let whitecolor = "gray-100"
  let darkcolor = "gray-800"
  return (
    <div className={`flex flex-col justify-between bg-${darkcolor} h-screen overflow-y-auto`}>
      <div className={`grid grid-cols-1 text-${whitecolor} font-sans`}>
        <div className={`m-4 grid border-b border-${whitecolor}`}>
          <NavLink exact to={'/'}>
            <img src= {logo} className="mb-4 mx-auto"/>
          </NavLink>
          <div className='mx-1 font-semibold text-lg'>Staff Portal</div>
        </div>
        <NavItem icon={<FaTachometerAlt />} title={"Dashboard"} to={"/"}/>
        <NavItem icon={<FaUserCircle/>} title={"Account"} to={"User"}/>
        <div className={`mx-4  mb-4 border-b border-${whitecolor}`}>
            <div className='mx-1 font-semibold text-lg'>Pages</div>
        </div>
        <NavItem icon={<FaCalendarAlt/>} title={"Events"} to={"Events"}/>
        {/* <NavItem icon={<FaFileAlt/>} title={"Forms"} to={"Forms"}/> */}
        <NavItem icon={<FaUsers/>} title={"Participants"} to={"Participants"}/>
        <NavItem icon={<FaCheckSquare/>} title={"Approvals"} to={"Approvals"}/>
        <NavItem icon={<FaScroll/>} title={"Feed"} to={"Feed"}/>
        <div className={`mx-4  mb-4 border-b border-${whitecolor}`}>
            <div className='mx-1 font-semibold text-lg'>Socials</div>
        </div>
        <SocialItem icon={<FaInstagram />} title={"Instagram"} to={"https://www.instagram.com/tinymightystrong/?hl=en"}/>
        <SocialItem icon={<FaFacebook />} title={"Facebook"} to={"https://www.facebook.com/tinymightystrong"}/>
        
      </div>
      <div className='mb-2 text-gray-100'>
          <SocialItem onClick={removeToken} icon={<FaSignOutAlt />} title={"Sign Out"} to = {`${workingSite}`}/>
      </div>
    </div>
    )
}
