import React from 'react'
import {Link} from "react-router-dom";
export default function DashboardCard({internalData, title, icon, link, color, isSingle=false}) {
  const cardInternal = isSingle ? 
    (
      <div className={`p-4 bg-${color} h-56 rounded-b-lg text-white text-lg flex items-center justify-center`}>
        {internalData}
      </div>
    )
    : 
    (
      <div className={`p-4 bg-${color} h-56 rounded-b-lg text-white text-lg grid items-center`}>
          <div className='grid grid-cols-2 gap-4'>
              <div className="grid-span-1 flex justify-center">
                  {icon}
              </div>
              <div className='grid-span-1'>
                  {internalData}
              </div>
          </div>
      </div>
    )

  return (
    <Link to={link}>
        <div className=" grid-span-1 rounded-b-xl shadow-2xl-custom">
            <div className='bg-white rounded-t-lg p-4 flex items-center justify-center h-16 text-gray-800 text-2xl font-bold rounded-t-lg'>
            {title}
            </div>
            {cardInternal}
        </div>
    </Link>
  )
}
