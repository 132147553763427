import React, {useState, useEffect} from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'
import ApprovalTableRow from './ApprovalTableRow'

export default function Approvals({api}) {
  const [pendingApprovals, updatePendingApprovals] = useState(null)
  const [pastApprovals, updatePastApprovals] = useState(null)
  
  useEffect(() => {
      api.listAllApproval()
          .then((approvalsToUse) => { 
              updatePendingApprovals(approvalsToUse.filter(approval => approval.approved === null || approval.approved === 0))
              updatePastApprovals(approvalsToUse.filter(approval => approval.approved != null && approval.approved != 0))
          });
  }, [])

  const getApprovalTable = (approvals, pending) => {
    return (
      <div className='bg-white rounded-lg shadow-2xl-custom'>
        <div className='w-full bg-white rounded-xl text-gray-800 p-3'>
          <table className='table-auto mx-auto text-lg w-full'>
            <thead className='border-b-2 border-gray-800'>
              <tr>
                <th className='pl-1 pr-6'>Event</th>
                <th className='pr-6'>Child</th>
                <th className='pr-6'>Parent</th>
                <th className='pr-6'>{pending ? 'Amount' : 'Status'}</th>
              </tr>
            </thead>
            <tbody className='divided-y divided-gray-800'>
              {approvals.map((approval) => (
                <ApprovalTableRow
                  fourthCol={pending ? `$${approval.amount.toFixed(2)}` : approval.approved > 0 ? 'Accepted' : 'Rejected'}
                  approval={approval}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>       
    )
  }


  
  return (pendingApprovals && pastApprovals && (
    <div className="grid grid-cols-8 lg:grid-cols-6">
    <div className="col-span-1"></div>
    <div className="col-span-6 lg:col-span-4">
      <ItemsTable 
        title={"Pending Approvals Requests"} 
        tableClassName={''}
        hasAction={false}
        action={{to:'/CreateForm', title:'Add Form'}}
        items = {pendingApprovals.length > 0 ? getApprovalTable(pendingApprovals, true) : <div className='px-2'>There are no pending approvals</div>}
      />
      <ItemsTable 
        title={"Past Approvals Requests"} 
        tableClassName={''}
        hasAction={false}
        action={{to:'/CreateForm', title:'Add Form'}}
        items = {pastApprovals.length > 0 ? getApprovalTable(pastApprovals, false) : <div className='px-2'>There are no past approvals</div>}
      />
     </div>
      <div className="col-span-1"></div>
    </div>
  ))
}


