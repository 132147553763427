import React from 'react'

export default function DeleteModal({handleDelete, handleCancel, title}) {
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex justify-center items-center">
        <div className="absolute inset-0 bg-gray-500 opacity-75"/>
        <div className="bg-white rounded-lg p-4 opacity-100 z-50">
            <p>{title}</p>
            <div className="flex justify-between mt-4">
                <button onClick={handleDelete} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md mr-2">Yes, delete</button>
                <button onClick={handleCancel} className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md">Cancel</button>
            </div>
        </div>
    </div>
  )
}
