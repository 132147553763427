import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom'
import EventAttendeeRow from './EventAttendeeRow';
import { NavLink } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

export default function Event({api}) {
    const [event, updateEvent] = useState(null)
    let {id} = useParams();
    const [image, updateImage] = useState(null)
    const [attendees, updateAttendees] = useState([])
    const [attendeesEventResourse, updateAttendeesEventResourse] = useState([])
    useEffect(() => {
        api.getOneEvent(id)
            .then((event) => { 
                updateEvent(event);
                getImage(event);
            });
    
        api.listAllAttendees()
            .then((attendeesToUse) => { 
                const attendeesForDesiredEvent = attendeesToUse.filter(attendee => attendee.event == id);
                updateAttendeesEventResourse(attendeesForDesiredEvent)
                return Promise.all(attendeesForDesiredEvent.map(attendee => api.getOneChild(attendee.child)));
            })
            .then(children => { updateAttendees(children); });
    }, []);
    
    const getImage = async (event) => {
        let image
        let imageStyle = {};
        try {
            const module = await import(`../../assets/${event.photoUrl}`);
            const importedImage = [{ name: event.photoUrl, image: module.default }];
            image = (<img src={importedImage.find(img => img.name === event.photoUrl).image} alt={event.photoUrl} className="h-3/5 w-3/5 rounded-lg border shadow-image-custom my-2" /> )
            imageStyle = {
                backgroundImage: `url(${importedImage.find(img => img.name === event.photoUrl).image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '0.5rem 0rem 0rem 0.5rem'
            };
        } 
        catch (error) {
            image = (<></>)
            imageStyle = {
            backgroundColor: `rgb(66, 153, 225, 0.5)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '0.5rem 0rem 0rem 0.5rem'
            };
        }
        updateImage({image: image, imageStyle: imageStyle})
    }

    const getDateAndTime = (timestamp) => {
        const eventDateTime = new Date(timestamp);
        const eventDate = {
          day: eventDateTime.getDate(),
          month: eventDateTime.toLocaleDateString('en-US', { month: 'short' }),
          year: eventDateTime.getFullYear()
        };
        const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
        return {date: eventDate, time: eventTime}
    }

    const attendeeTable = () => {
        if (attendees.length > 0) {
            return (
                <table className='text-left table-auto'>
                    <thead className='border-gray-800 border-b-2'>
                        <tr>
                            <th className='pl-1 pr-4'>Child</th>
                            <th className='pr-4'>Parent</th>
                            <th className='pr-4'>Parent's Number</th>
                            <th className='pr-4'>Registered</th>
                            <th className='pr-1'>Paid</th>
                        </tr>
                    </thead>
                    <tbody className='divided-y divided-gray-800'>
                        {attendees.map(attendee => <EventAttendeeRow api={api} attendee={attendee} attendeeDetails={attendeesEventResourse}/>)}
                    </tbody>
                </table>
        )
        }
        else {
            return (<div>No people are currently signed up.</div>)
        }
    }

    const addEditButton = () => {
        return (
            <NavLink to={`/Event/Edit/${id}`}>    
                    <div className='relative z-10'>
                        <div className=''>
                        </div>
                        <div className=' absolute top-0 left-0 -mt-5 mr-2 -ml-6 rounded-full h-10 w-10 flex items-center justify-center bg-gray-800 text-white text-2xl font-semibold'>
                        <FaEdit className='' />
                        </div>
                    </div>   
            </NavLink>
        )
    }


  return (event && image && attendees && attendeesEventResourse &&(
    <div className='flex justify-center m-8'>
        <div/>
        <div className='bg-white rounded-xl w-full lg:w-3/4 xl:w-3/5 text-gray-800 shadow-2xl-custom'>
            <div className='flex justify-center w-full'>
                <div className='w-full'>
                    <div className='text-3xl font-bold w-full text-center bg-blue-500 text-white mb-2 rounded-t-xl p-2'>{event.type} - {event.name}</div>
                    <div className="text-md font-semibold text-center m-2 pt-2">{getDateAndTime(event.dateAndTime).date.month} {getDateAndTime(event.dateAndTime).date.day}, {getDateAndTime(event.dateAndTime).date.year} - {getDateAndTime(event.dateAndTime).time}</div>
                    <div className='flex justify-center px-2'>{image.image}</div>
                    <div className='flex justify-center p-4'>{event.description}</div>
                </div>
            </div>
            <div className='grid justify-center p-2'>
                <div className='font-bold text-xl mb-1'>{event.type} Attendees - Count: {attendees.length}</div>
                {attendeeTable()}
            </div>
        </div>
        <div>{addEditButton()}</div>
    </div>
  ))
}
