import React, {useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, useParams } from 'react-router-dom'
import { NavLink } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { PhoneInput } from 'react-rainbow-components';

export default function Participant({api}) {
    const [participant, updateParticipant] = useState(null)
    const [parent, updateParent] = useState(null)
    let {id} = useParams();

    useEffect(() => {
        api.getOneChild(id)
            .then((child) => { 
                updateParticipant(child);
                api.getOneParent(child.parentID).then((parent) => {
                    updateParent(parent)
                })
            });
    }, []);
    
    const getDateAndTime = (timestamp) => {
        const workingDateTime = new Date(timestamp);
        const workingDate = {
          day: workingDateTime.getDate(),
          month: workingDateTime.toLocaleDateString('en-US', { month: 'short' }),
          year: workingDateTime.getFullYear()
        };
        const today = new Date()
        const differenceMs = today - workingDateTime
        const ageDate = new Date(differenceMs)
        const age = Math.abs(ageDate.getUTCFullYear() - 1970)
        const eventTime = workingDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
        return {date: workingDate, time: eventTime, age: age}
    }

    const itemGen = (title, details) => {
        return (
            <div className="text-md ml-2 my-1 pt-2 px-2"><span className='font-semibold'>{title}:</span> {details}</div>
        )
    }
    const fieldGen = (title, details) => {
        return (
            <div className="text-md ml-2 my-1 pt-2 px-2"><span className='font-semibold'>{title}:</span> <div className=' mt-1 mx-4'>{details}</div></div>
        )
    }
    const addressGen = (title, street1, street2, city, state, zip) => {
        return (
            <div className="text-md ml-2 my-1 pt-2 px-2"><span className='font-semibold'>{title}:</span> 
                <div className=' mt-1 mx-4'>
                    {street1}
                    {street2 ? <><br/>{street2}</>: null}
                    <><br/>{city}, {state} {zip}</>
                </div>
            </div>
        )
    }

  return (participant && parent && (
    <div className='flex justify-center m-8'>
        <div/>
        <div className='bg-white rounded-xl w-full lg:w-3/4 xl:w-3/5 text-gray-800 shadow-2xl-custom'>
            <div className='flex justify-center w-full'>
                <div className='w-full pb-2'>
                    <div className='text-3xl font-bold w-full text-center bg-blue-500 text-white mb-2 rounded-t-xl p-2'>{participant.firstName} {participant.lastName}</div>
                    {itemGen(`Full Name`, `${participant.firstName} ${participant.lastName}`)}
                    {itemGen(`Age`, `${getDateAndTime(participant.dateOfBirth).age}`)}
                    {itemGen('Date of Birth', `${getDateAndTime(participant.dateOfBirth).date.month} ${getDateAndTime(participant.dateOfBirth).date.day}, ${getDateAndTime(participant.dateOfBirth).date.year}`)}
                    {addressGen(`Address`, participant.streetAddressLine1, participant.streetAddressLine2, participant.city, participant.state, participant.zipcode)}
                    {itemGen(`Cell Number`, `${participant.cellPhone ? participant.cellPhone : 'None'}`)}
                    {itemGen(`Email`, `${participant.email ? participant.email : 'None'}`)}
                    {itemGen(`Grade`, `${participant.grade}`)}
                    {itemGen(`Gender`, `${participant.gender}`)}
                    {itemGen(`Race`, `${participant.race}`)}
                    {itemGen(`Ethnicity`, `${participant.ethnicity}`)}
                    {itemGen(`Enrolled in BVU School District`, `${participant.bvschoolDist ? 'Yes' : 'No'}`)}
                    {itemGen(`School of Child`, `${participant.schoolName}`)}
                    {itemGen(`Child Lives With`, `${participant.parentLiveWithName}`)}
                    {itemGen(`Health Insurance Provider`, `${participant.healthProvider}`)}
                    {fieldGen(`Medical Problems (including requiring medication)`, `${participant.medicalProblems ? participant.medicalProblems : "None"}`)}
                    {fieldGen(`Allergies`, `${participant.allergies ? participant.allergies : "None"}`)}
                    {fieldGen(`Specific Diet`, `${participant.diet ? participant.diet : "None"}`)}
                    {fieldGen(`Disabilities/ Required Accommodation`, `${participant.disabilities ? participant.disabilities : "None"}`)}
                    {fieldGen(`Medications being Prescribed`, `${participant.medication ? participant.medication : "None"}`)}
                    {itemGen(`EpiPen`, `${participant.epipen ? 'Yes' : 'No'}`)}
                    {itemGen(`Asthma`, `${participant.asthma ? 'Yes' : 'No'}`)}
                    {fieldGen(`Other Assistance`, `${participant.otherAssistance ? participant.otherAssistance : "None"}`)}
                    <div className="text-xl m-2 pt-8 px-2"><span className='font-semibold'>Parent's Info</span></div>
                    {itemGen(`Full Name`, `${parent.firstName} ${parent.lastName}`)}
                    {itemGen('Date of Birth', `${getDateAndTime(parent.dateOfBirth).date.month} ${getDateAndTime(parent.dateOfBirth).date.day}, ${getDateAndTime(parent.dateOfBirth).date.year}`)}
                    {addressGen(`Address`, parent.streetAddressLine1, parent.streetAddressLine2, parent.city, parent.state, parent.zipcode)}
                    {addressGen(`Mailing Address`, parent.mailing_streetAddressLine1, parent.mailing_streetAddressLine2, parent.mailing_city, parent.mailing_state, parent.mailing_zipcode)}
                    {itemGen(`Country of residence`, `${parent.countryResidence}`)}
                    {itemGen(`Cell Number`, `${parent.cellPhone ? parent.cellPhone : 'None'}`)}
                    {itemGen(`Home Number`, `${parent.homePhone ? parent.homePhone : 'None'}`)}
                    {itemGen(`Email`, `${parent.email ? parent.email : 'None'}`)}
                    {itemGen(`Race`, `${parent.race}`)}
                    {itemGen(`Ethnicity`, `${parent.ethnicity}`)}
                    {itemGen(`Employment Status`, `${parent.workStatus}`)}
                    {itemGen(`Emergency Contact 1`, `${parent.emergencyContact1_firstName} ${parent.emergencyContact1_lastName} -  ${parent.emergencyContact1_phone}`)}
                    {itemGen(`Emergency Contact 2`, `${parent.emergencyContact2_firstName} ${parent.emergencyContact2_lastName} -  ${parent.emergencyContact2_phone}`)}
                </div>
            </div>

        </div>
        <div></div>
    </div>
  ))
}