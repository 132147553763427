import React from 'react'

export default function AWSAccountCreationModal({createAccount, logo}) {
  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex justify-center items-center">
      <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      <div className="bg-white rounded-lg p-4 opacity-100 z-50">
        <img src={logo} alt="Logo" />
        <div className=' text-xl pt-4'>Welcome!<br/>Finish creating your account below.</div>
        <div className="flex justify-between py-2">
          <form onSubmit={createAccount}>
            <label htmlFor="firstName">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="First Name"
              required
            />
            <label htmlFor="lastName">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              placeholder="Last Name"
              required
            />
            <div className="flex justify-between mt-4">
                <button type='submit' className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-2">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
