import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DateTimePicker, Picklist, PicklistOption  } from 'react-rainbow-components';
import ItemsTable from '../../components/ItemsTable/ItemsTable';
import { useNavigate } from 'react-router-dom';

export default function EventEdit({ api }) {
    const [nameState, updateNameState] = useState(null);
    const [typeState, updateTypeState] = useState(null);
    const [descriptionState, updateDescriptionState] = useState(null);
    const [dateTime, updateDateTime] = useState(null);
    const navigate = useNavigate();
    const [photoUrlState, updatePhotoUrlState] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            api.getOneEvent(id).then((event) => {
                updateNameState(event.name);
                updateTypeState(event.type);
                updateDescriptionState(event.description);
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const localTimestamp = new Date(event.dateAndTime).toLocaleString("en-US", { timeZone: userTimeZone });
                updateDateTime(localTimestamp);
                updatePhotoUrlState(event.photoUrl)
                
            });
        } else {
            updateNameState('');
            updateTypeState('');
            updateDescriptionState('');
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeNow = new Date().toLocaleString("en-US", { timeZone: userTimeZone });
            updateDateTime(timeNow);
            updatePhotoUrlState('')
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let localDate = new Date (dateTime)
        let newEvent = api.Event(nameState.trim(),localDate.toISOString(),typeState.trim(),descriptionState.trim(), photoUrlState ? photoUrlState.trim() : photoUrlState)
        if (id) {
            api.updateEvent(id, newEvent).then((newid)=> {navigate(`/Event/${newid}`);})
        }
        else {
            api.createEvent(newEvent).then((event) => {
                navigate(`/Event/${event.id}`)
            })
        }
    };

    const containerStyles = {
        maxWidth: 300,
    };

    const getForm = () => {
        return (
            <div className="bg-white rounded-xl p-2 w-full text-gray-800 shadow-2xl-custom">
                <form onSubmit={handleSubmit} className="w-full p-4 text-lg font-semibold text-gray-800">
                    <div className="mb-3 pb-4 border-b border-gray-800 border-opacity-50">
                        <label htmlFor="type">
                            <span className="text-red-500">*</span> Event Type
                        </label>
                        <input
                            id="type"
                            type="text"
                            name="type"
                            className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter type..."
                            defaultValue={typeState}
                            onChange={(e) => updateTypeState(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3 pb-4 border-b border-gray-800 border-opacity-50">
                        <label htmlFor="name">
                            <span className="text-red-500">*</span> Event Name
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter title..."
                            value={nameState}
                            onChange={(e) => { updateNameState(e.target.value);}}
                            required
                        />
                    </div>
                    <div className="mb-3 pb-5 border-b border-gray-800 border-opacity-50">
                            <label htmlFor="datetimepicker-1" className='mb-2'>
                                <span className="text-red-500">*</span> Date and time
                            </label>
                            <div className='font-medium'>
                                <DateTimePicker
                                    id="datetimepicker-1"
                                    label=""
                                    value={dateTime}
                                    onChange={(value) => updateDateTime(value)}
                                    formatStyle="large"
                                    required
                                    borderRadius = 'semi-rounded'
                                />
                            </div>
  
                    </div>
                    <div className="mb-3 pb-4 border-b border-gray-800 border-opacity-50">
                        <label htmlFor="photoUrl">
                            &nbsp;&nbsp;&nbsp;Photo Url
                        </label>
                        <input
                            id="photoUrl"
                            type="text"
                            name="photoUrl"
                            className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter url..."
                            defaultValue={photoUrlState}
                            onChange={(e) => updatePhotoUrlState(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description">
                            <span className="text-red-500">*</span> Description 
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            rows="8"
                            className="my-1 p-1 pl-3 w-full border rounded-xl border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter description..."
                            value={descriptionState}
                            onChange={(e) => updateDescriptionState(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div className="flex justify-end">
                        <button onClick={()=> {id ? navigate(`/Event/${id}`) : navigate(`/Events`)}} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                            Cancel
                        </button>
                        {id && (
                            <button onClick={() => {api.deleteEvent(id); navigate(`/`);}} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                                Delete
                            </button>
                        )}
                        <button type="submit" className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-md hover:bg-indigo-600">
                            {id ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        )}

    return ( nameState !== null && descriptionState !== null && typeState !== null && dateTime !== null && (
            <div className="grid grid-cols-8 lg:grid-cols-6">
                <div className="col-span-1"></div>
                <div className="col-span-6 lg:col-span-4">
                    <ItemsTable
                        title={id ? "Edit Event" : "Create Event"}
                        tableClassName={''}
                        hasAction={false}
                        action={null}
                        items={[getForm()]}
                    />
                </div>
                <div className="col-span-1"></div>
            </div>
        )
    );
}