import React from 'react'
import { FaAngleRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export default function NavItem({icon, title, to}) {
  return (
    <NavLink exact to={to} className="nav-link mx-1 px-4 mb-2 flex justify-between items-center" activeClassName="active">
        <div className='flex items-center'>
            {icon}
            <span className='pl-2'>{title}</span>
        </div>
        <FaAngleRight />
    </NavLink>
  )
}
