import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ItemsTable from '../../components/ItemsTable/ItemsTable';
import { useNavigate } from 'react-router-dom';

export default function Approval({api}) {
    const [approvalState, updateApprovalState] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        api.getOneApproval(id).then((approvalToUse) => {
            updateApprovalState(approvalToUse);
        });
    }, []);

    const handleDeny = () => {
        setShowDenyModal(true)
    };
    const handleApprove = () => {
        setShowApproveModal(true)
    };

    const getDateAndTime = (timestamp) => {
        const eventDateTime = new Date(timestamp);
        const eventDate = {
          day: eventDateTime.getDate(),
          month: eventDateTime.toLocaleDateString('en-US', { month: 'short' }),
          year: eventDateTime.getFullYear()
        };
        const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true/*, timeZoneName: 'short' */});
        return {date: eventDate, time: eventTime}
    }

    const containerStyles = {
        maxWidth: 300,
    };

    const getApprovalStatus = (approval) => {
        if (approval > 0) {
            return 'Approved'
        }
        else if (approval < 0) {
            return 'Denied'
        }
        else {
            return 'Pending'
        }
    }

    const confirmApprove = async (e) => {
        e.preventDefault();
        const newApproval = api.Approval(approvalState.eventId, approvalState.childId, approvalState.amount, approvalState.description, 1, e.target.staffDescription.value)
        await api.updateApproval(id, newApproval)
        setShowApproveModal(false)
        setShowDenyModal(false)
        navigate(`/Approvals`)
      };

    const confirmDeny = async (e) => {
        e.preventDefault();
        const newApproval = api.Approval(approvalState.eventId, approvalState.childId, approvalState.amount, approvalState.description, -1, e.target.staffDescription.value)
        await api.updateApproval(id, newApproval)
        setShowApproveModal(false)
        setShowDenyModal(false)
        navigate(`/Approvals`)
      };
    
      const cancelApproval = () => {
        setShowApproveModal(false)
        setShowDenyModal(false)
      };

    const getApprovalInfo = () => {
        return (
            <div className="bg-white rounded-xl w-full text-gray-800 shadow-2xl-custom">
                <div className='text-3xl font-bold w-full text-center bg-green-500 text-white mb-2 rounded-t-xl p-2'>{approvalState.eventName} - {approvalState.childFirstName} {approvalState.childLastName}</div>
                <div className='p-2'>
                    {itemGen(`Approval`, `${getApprovalStatus(approvalState.approved) }`)}
                    {itemGen(`Event`, `${approvalState.eventName} - ${getDateAndTime(approvalState.eventDateAndTime).date.month}, ${getDateAndTime(approvalState.eventDateAndTime).date.day} ${getDateAndTime(approvalState.eventDateAndTime).date.year}`)}
                    {itemGen(`Child's Name`, `${approvalState.childFirstName} ${approvalState.childLastName}`)}
                    {itemGen(`Parent's Name`, `${approvalState.parentFirstName} ${approvalState.parentLastName}`)}
                    {itemGen(`Amount`, `$${approvalState.amount.toFixed(2)}`)}
                    {fieldGen(`Parent's Explanation`, `${approvalState.description ? approvalState.description : 'None'}`)}
                    {fieldGen(`Staff's Explanation`, `${approvalState.staffDescription ? approvalState.staffDescription : 'None'}`)}
                    <div className="flex justify-end">
                            <button onClick={()=> navigate(`/Approvals`)} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                                Cancel
                            </button>
                            <button onClick={() => {handleDeny()}} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                                Deny
                            </button>
                            <button onClick={() => {handleApprove()}} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-md hover:bg-indigo-600">
                                Approve
                            </button>
                        </div>
                </div>
            </div>
        )}

        const itemGen = (title, details) => {
            return (
                <div className="text-md ml-2 my-1 pt-2 px-2"><span className='font-semibold'>{title}:</span> {details}</div>
            )
        }
        const fieldGen = (title, details) => {
            return (
                <div className="text-md ml-2 my-1 pt-2 px-2"><span className='font-semibold'>{title}:</span> <div className=' mt-1 mx-4'>{details}</div></div>
            )
        }

    return ( approvalState && (
            <div className="grid grid-cols-8 lg:grid-cols-6">
                <div className="col-span-1"></div>
                <div className="col-span-6 lg:col-span-4">
                    <ItemsTable
                        title={`Approval Request`}
                        tableClassName={''}
                        hasAction={false}
                        action={null}
                        items={[getApprovalInfo()]}
                    />
                </div>
                <div className="col-span-1"></div>
                {showApproveModal && (
                    <div className="fixed z-50 inset-0 overflow-y-auto flex justify-center items-center">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        <div className="bg-white rounded-lg p-4 opacity-100 z-50">
                            <form onSubmit={confirmApprove}>
                                <label htmlFor="staffDescription" className='font-semibold'>
                                    Add an explanation for this approval
                                </label>
                                <textarea
                                    id="staffDescription"
                                    name="staffDescription"
                                    rows="8"
                                    className="my-1 p-1 pl-3 w-full border rounded-xl border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    placeholder="Enter description..."
                                    required
                                ></textarea>
                                <div className="flex justify-between mt-4">
                                    <button type='submit' className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mr-2">Approve</button>
                                    <button onClick={cancelApproval} className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    )}
                {showDenyModal && (
                    <div className="fixed z-50 inset-0 overflow-y-auto flex justify-center items-center">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        <div className="bg-white rounded-lg p-4 opacity-100 z-50">
                            <form onSubmit={confirmDeny}>
                                <label htmlFor="staffDescription" className='font-semibold'>
                                    Add an explanation for this denial
                                </label>
                                <textarea
                                    id="staffDescription"
                                    name="staffDescription"
                                    rows="8"
                                    className="my-1 p-1 pl-3 w-full border rounded-xl border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    placeholder="Enter description..."
                                    required
                                ></textarea>
                                <div className="flex justify-between mt-4">
                                    <button type='submit' className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md mr-2">Deny</button>
                                    <button onClick={cancelApproval} className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 rounded-md">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    )}
            </div>
        )
    );
}
