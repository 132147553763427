import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ItemsTable from '../../components/ItemsTable/ItemsTable';
import { useNavigate } from 'react-router-dom';

export default function PostEdit({api}) {
    const [nameState, updateNameState] = useState(null);
    const [descriptionState, updateDescriptionState] = useState(null);
    const [dateTime, updateDateTime] = useState(null);
    const navigate = useNavigate();
    const [photoUrlState, updatePhotoUrlState] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            api.getOnePost(id).then((event) => {
                updateNameState(event.name);
                updateDescriptionState(event.description);
                const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const localTimestamp = new Date(event.dateAndTime).toLocaleString("en-US", { timeZone: userTimeZone });
                updateDateTime(localTimestamp);
                updatePhotoUrlState(event.photoUrl)
                
            });
        } else {
            updateNameState('');
            updateDescriptionState('');
            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const timeNow = new Date().toLocaleString("en-US", { timeZone: userTimeZone });
            updateDateTime(timeNow);
            updatePhotoUrlState('')
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let localDate = new Date (dateTime)
        let newPost = api.Post(nameState.trim(),localDate.toISOString(),descriptionState.trim(), photoUrlState ? photoUrlState.trim() : photoUrlState)
        if (id) {
            api.updatePost(id, newPost).then((newid)=> {navigate(`/Feed`);})
        }
        else {
            api.createPost(newPost).then((event) => {
                navigate(`/Feed`)
            })
        }
    };

    const containerStyles = {
        maxWidth: 300,
    };

    const getForm = () => {
        return (
            <div className="bg-white rounded-xl p-2 w-full text-gray-800 shadow-2xl-custom">
                <form onSubmit={handleSubmit} className="w-full p-4 text-lg font-semibold text-gray-800">
                    <div className="mb-3 pb-4 border-b border-gray-800 border-opacity-50">
                        <label htmlFor="name">
                            <span className="text-red-500">*</span> Post Name
                        </label>
                        <input
                            id="name"
                            type="text"
                            name="name"
                            className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter title..."
                            value={nameState}
                            onChange={(e) => { updateNameState(e.target.value);}}
                            required
                        />
                    </div>
                    <div className="mb-3 pb-4 border-b border-gray-800 border-opacity-50">
                        <label htmlFor="photoUrl">
                            &nbsp;&nbsp;&nbsp;Photo Url
                        </label>
                        <input
                            id="photoUrl"
                            type="text"
                            name="photoUrl"
                            className="my-1 p-1 pl-3 w-full border rounded-lg border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter url..."
                            defaultValue={photoUrlState}
                            onChange={(e) => updatePhotoUrlState(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="description">
                            <span className="text-red-500">*</span> Description 
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            rows="8"
                            className="my-1 p-1 pl-3 w-full border rounded-xl border-gray-500 shadow-md focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Enter description..."
                            value={descriptionState}
                            onChange={(e) => updateDescriptionState(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div className="flex justify-end">
                        <button onClick={()=> navigate(`/Feed`)} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                            Cancel
                        </button>
                        {id && (
                            <button onClick={() => {api.deletePost(id); navigate(`/`);}} className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg hover:bg-indigo-600 mr-2">
                                Delete
                            </button>
                        )}
                        <button type="submit" className="bg-gray-800 text-white py-2 px-4 rounded-md shadow-md hover:bg-indigo-600">
                            {id ? "Update" : "Create"}
                        </button>
                    </div>
                </form>
            </div>
        )}

    return ( nameState !== null && descriptionState !== null && dateTime !== null && (
            <div className="grid grid-cols-8 lg:grid-cols-6">
                <div className="col-span-1"></div>
                <div className="col-span-6 lg:col-span-4">
                    <ItemsTable
                        title={id ? "Edit Post" : "Create Post"}
                        tableClassName={''}
                        hasAction={false}
                        action={null}
                        items={[getForm()]}
                    />
                </div>
                <div className="col-span-1"></div>
            </div>
        )
    );
}
