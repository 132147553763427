import React from 'react'
import Header from '../../components/Header/Header'
import { Outlet } from 'react-router-dom'
import Upper from '../Upper/Upper'

//Name is hard coded here
export default function Layout({account, workingSite}) {
  return (
    <div className='grid grid-cols-6'>
        <div className='col-span-1'>
          <Header workingSite={workingSite}/>
        </div>
        <div className="col-span-5 bg-gray-300 h-screen overflow-y-auto">
          <Upper userFullName={`${account.firstName} ${account.lastName}`}/>
          <div className="">
            <Outlet/>
          </div>
        </div>
        {/* <Footer/> */}
    </div>
  )
}
