import './App.css';
import React, {useState, useEffect} from 'react'
import Layout from '../../components/Layout/Layout'
import Dashboard from '../../views/Dashboard/Dashboard'
import {Routes, Route} from "react-router-dom"
import NoMatch from '../../views/NoMatch/NoMatch'
import Events from '../Events/Events';
import Forms from '../Forms/Forms';
import Approvals from '../Approvals/Approvals';
import Approval from '../Approvals/Approval';
import Participants from '../Participants/Participants';
import Participant from '../Participants/Participant';
import Feed from '../Feed/Feed';
import Event from '../Event/Event';
import EventEdit from '../Event/EventEdit';
import PostEdit from '../Feed/PostEdit';
import Account from '../Account/Account';
import AccountEdit from '../Account/AccountEdit';
import { useContext } from 'react'
import {getContext} from  "../../services/ContextFactory"
import _ from 'lodash'
import logo from './../../assets/tmslogoblack.png'
import AWSAccountCreationModal from '../../components/Modals/AWSAccountCreationModal';

function App() {
  //EX for working site "https://incomparable-tulumba-5e8323.netlify.app" or 'https://localhost:3000'
  const workingSite = 'https://staff.tinymightystrong.org'
  const [account, setAccount] = useState(null)
  const [user, setUser] = useState("");
  let api = useContext(getContext())

  useEffect(() => {
    //on page load look for params in the URL
    const params = new URLSearchParams(window.location.hash.substring(1));
    let token = params.get("id_token");
    //if there isn't token in params, look in local storage
    if (!token) {
      token = localStorage.getItem("auth");
    }
    //if there's an auth token in either place, store in local storage then verify
    if (token) {
      localStorage.setItem("auth", token);

      api
        .auth(token)
        .then((workingUser) => {
          const username = workingUser['cognito:username']
          const email = workingUser.email
          api.getOneStaffUser(username, email).then((account)=>{ 
            if (JSON.stringify(account) === JSON.stringify([null])) {
              setAccount(null)
            }
            else {
              setAccount(account)
            }
            setUser(workingUser);
          })

        })

        //if there's an error verifying remove auth from state and localStorage
        .catch((error) => {
          console.log(error);
          setUser(null);
          token = null;
          localStorage.removeItem("auth");
        });

      //clear params from URL
      const { protocol, host, pathname } = window.location;
      const newUrl = `${protocol}//${host}${pathname}`;

      const urlWithoutToken = new URL(newUrl);
      urlWithoutToken.searchParams.delete("id_token");
      urlWithoutToken.searchParams.delete("access_token");
      urlWithoutToken.searchParams.delete("expires_in");
      urlWithoutToken.searchParams.delete("token_type");

      window.history.replaceState(
        {},
        document.title,
        urlWithoutToken.toString()
      );
    }

    //if there isn't a token redirect to the login page
    if (!token) {
      setUser(null);
      window.location.href =
        `https://tinymightystaff.auth.us-west-1.amazoncognito.com/login?client_id=1u5qrgqghv1nbnqmdjcqo09ghm&response_type=token&scope=email+openid+phone&redirect_uri=${workingSite}`;
    }
  }, []);

  const createAccount = (e) => {
    e.preventDefault();
    const username = user['cognito:username']
    const email = user.email
    const firstName = e.target.firstName.value
    const lastname = e.target.lastName.value
    const newUser = api.User(username, email, firstName, lastname)
    api.createStaffUser(newUser).then((account) => {
      setAccount(account)
    })
  };

  const properPage = (account) => {
    if (account){
      return (
        <Routes>
          <Route path="/" element={<Layout account={account} workingSite={workingSite}/>}>
            <Route index element={<Dashboard api={api}/>} />
            <Route path="Events" element={<Events api={api}/>} />
            <Route path="Forms" element={<Forms api={api}/>} />
            <Route path="Participants" element={<Participants api={api} />} />
            <Route path="Participants/:id" element={<Participant api={api} />} />
            <Route path="Approvals" element={<Approvals api={api} />} />
            <Route path="Approvals/:id" element={<Approval api={api}/>}/>
            <Route path="Feed" element={<Feed api={api} />} />
            <Route path="User" element={<Account api={api} account={account}/>} />
            {/* <Route path="CreateAccount" element={<AccountEdit api={api}/>} /> */}
            <Route path="Event/:id" element={<Event api={api} />} />
            <Route path="Event/Edit/:id" element={<EventEdit api={api}/>}/>
            <Route path="Post/Edit/:id" element={<PostEdit api={api}/>}/>
            <Route path="CreatePost" element={<PostEdit api={api}/>}/>
            <Route path="CreateEvent" element={<EventEdit api={api}/>}/>
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      )
    }

    else { 
      return (<AWSAccountCreationModal createAccount={createAccount} logo={logo}/>)
    }
  }

  return (user &&(<>{properPage(account)}</>))
}

export default App;


/*
  V2 Additions:
  Get image uploader in A2
  Staff portal mapping after editing or creating is canceled ( go to last page)
  How did you hear about us survey info
*/