import React from 'react'
import { FaUserCircle } from "react-icons/fa";
import {Link} from "react-router-dom";
import { NavLink } from "react-router-dom";


export default function Upper({userFullName}) {

  return (
    <div className="sticky top-0 shadow-xl-custom bg-white px-4 py-3 flex justify-between items-center z-50">
      <NavLink exact to={'/'}>
        <div className='font-bold text-2xl'>
            Tiny Mighty Strong
        </div>
      </NavLink>
      <div className='flex items-center gap-4'>
        <Link to={"User"}>
          <div className='flex items-center'>
              <span className='mr-2'>{userFullName}</span>
              <FaUserCircle size={24}/>
          </div>
        </Link>
      </div>
    </div>
  )
}
