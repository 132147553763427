import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";

export default function EventAttendeeRow({attendee, api, attendeeDetails}) {
    const [parent, updateParent] = useState(null)
    const [desiredDetails, updateDesiredDetails] = useState(null)
    useEffect(() => {
        // Fetch event details and attendees only when component mounts or id changes
        api.getOneParent(attendee.parentID)
            .then((parent) => { 
                updateParent(parent);
                updateDesiredDetails(attendeeDetails.find(obj => obj.child === attendee.child_id))
            });
    }, []); // Trigger effect only when api or id change


    const fullyRegisted = (desiredDetails) => {
        if (desiredDetails.formFilled && desiredDetails.responseAttendance && desiredDetails.notifyEmergency &&
            desiredDetails.medicalExpenses && desiredDetails.photoRelease && desiredDetails.damageAndRefund &&
            desiredDetails.staffExpectation && desiredDetails.codeOfConduct) {
                return (<td><NavLink to={`/Participants/${attendee.child_id}`}>Yes</NavLink></td>)
            }
        return (<td><NavLink to={`/Participants/${attendee.child_id}`}>No</NavLink></td>)
    }
    
  return (parent && desiredDetails &&(
      <tr className='p-4 border-b'>
      <td className='pl-1 pr-4'><NavLink to={`/Participants/${attendee.child_id}`}>{attendee.firstName} {attendee.lastName}</NavLink></td>
      <td className='pr-4'><NavLink to={`/Participants/${attendee.child_id}`}>{attendee.parentFullName}</NavLink></td>
      <td className='pr-1'><NavLink to={`/Participants/${attendee.child_id}`}>{parent.homePhone}</NavLink></td> 
      {fullyRegisted(desiredDetails)}
      {desiredDetails ? "Yes" : "No"}
      </tr>
  ))
}
