import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";

export default function ParticipantsTableRow({attendee, api}) {

    const getDateAndTime = (timestamp) => {
        const eventDateTime = new Date(timestamp);
        const eventDate = {
          day: eventDateTime.getDate(),
          month: eventDateTime.toLocaleDateString('en-US', { month: 'short' }), // Using short month name
          year: eventDateTime.getFullYear()
        };
        const eventTime = eventDateTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true, timeZoneName: 'short' });
        return {date: eventDate, time: eventTime}
    }
    
  return ((
      <tr className='p-4 border-b'>
      <td className='pl-1 pr-6'><NavLink to={`/Participants/${attendee.child_id}`}>{attendee.firstName} {attendee.lastName}</NavLink></td>
      <td className='pr-6'><NavLink to={`/Participants/${attendee.child_id}`}>{attendee.cellPhone}</NavLink></td> 
      <td className='pr-6'><NavLink to={`/Participants/${attendee.child_id}`}>{getDateAndTime(attendee.dateOfBirth).date.month} {getDateAndTime(attendee.dateOfBirth).date.day} {getDateAndTime(attendee.dateOfBirth).date.year}</NavLink></td>
      <td className='pr-6'><NavLink to={`/Participants/${attendee.child_id}`}>{attendee.parentFullName}</NavLink></td>
      </tr>
  ))
}
