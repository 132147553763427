import React from 'react'
import ItemsTable from '../../components/ItemsTable/ItemsTable'

export default function Forms({api}) {
  return (
    <div>
      <ItemsTable 
        title={"Forms"} 
        tableClassName={'grid grid-cols-1 xl:grid-cols-2 gap-4'}
        hasAction={true}
        action={{to:'/CreateForm', title:'Add Form'}}
        //   items={pastEvents.map((item) => (
        //     <EventRow images={images} item={item} getDateAndTime={getDateAndTime}/>
        //   ))}
        // items = {Array.from({ length: 10 }, (_, index) => index + 1).map((number) => (
        //     <div key={number}>{number}</div>
        //   ))}
        items = {(<div className='bg-white h-36  text-center p-auto rounded-lg shadow-2xl-custom'><div className='h-12 bg-pink-400 rounded-t-lg text-white text-2xl flex justify-center items-center'>Forms</div><div className='h-24 flex justify-center items-center'>Coming Soon</div></div>)}
        />
    </div>
  )
}
